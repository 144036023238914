import * as types from "../types/index";
import { authHeader } from "../headers";
import { broker_logout } from "./authActions";
import axios from "axios";
import toast from "react-hot-toast";
import { refresh_profile } from "./authActions";

const url = "https://nasdmobile.nasdng.com/api/v1";
// const url = "http://146.190.205.147:3011/v1";

export const get_all_individual_investors =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_INDIV_INVESTOR_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(
        `${url}/investor/individual-investors/?application=${getData}`,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({ type: types.GET_INDIV_INVESTOR_SUCCESS, payload: data });
      }
    } catch (error) {
      console.log(error);
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({ type: types.GET_INDIV_INVESTOR_FAIL, payload: message });
      toast.error(message, { position: "top-right " });
    }
  };

export const get_single_individual_investors =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.SINGLE_INDIV_INVESTOR_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(
        `${url}/investor/individual-investors/${id}?application=${getData}`,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({ type: types.SINGLE_INDIV_INVESTOR_SUCCESS, payload: data });
      }
    } catch (error) {
      console.log(error);
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({ type: types.SINGLE_INDIV_INVESTOR_FAIL, payload: message });
      toast.error(message, { position: "top-right " });
    }
  };

export const update_single_investor_chn =
  () => async (dispatch, getState) => {};

export const get_all_corporate_investors = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_CORP_INVESTOR_REQUEST });

    const {
      auth: { detail },
    } = getState();

    const getData = window.localStorage.getItem("devoper");

    const { data } = await axios.get(
      `${url}/investor/corporate-investors?application=${getData}`,
      {
        headers: authHeader(detail.token),
      }
    );

    if (data) {
      dispatch({ type: types.GET_CORP_INVESTOR_SUCCESS, payload: data });
    }
  } catch (error) {
    console.log(error);
    const message = error.response.data.error
      ? error.response.data.error
      : "Something went wrong";
    if (message === "Something went wrong") {
      dispatch(broker_logout());
    }
    dispatch({ type: types.GET_CORP_INVESTOR_FAIL, payload: message });
    toast.error(message, { position: "top-right " });
  }
};

export const confirm_investors_account_number =
  (ACCOUNTNUMBER) => async (dispatch, getState) => {
    const url = "https://apis.nasdotcng.com/api/account";

    try {
      dispatch({ type: types.CONFIRM_INVESTOR_ACCOUNT_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(`${url}/${ACCOUNTNUMBER}`, {
        headers: authHeader(detail.token),
      });

      if (data) {
        dispatch({
          type: types.CONFIRM_INVESTOR_ACCOUNT_SUCCESS,
          payload: data,
        });
        return;
      }

      toast.error("Account number does not exist", { position: "top-right " });
    } catch (error) {
      console.log(error);
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({ type: types.CONFIRM_INVESTOR_ACCOUNT_FAIL, payload: message });
      toast.error("Account number does not exist", { position: "top-right " });
    }
  };

export const confirm_investors_account_status =
  (status, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.UPDATE_INVESTOR_STATUS_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.patch(
        `${url}/investor/update-account-status/${id}?application=${getData}`,
        status,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({ type: types.UPDATE_INVESTOR_STATUS_SUCCESS, payload: data });
        toast.success(`Investors status has been updated`, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({ type: types.UPDATE_INVESTOR_STATUS_FAIL, payload: message });
      toast.error(message, { position: "top-right " });
    }
  };

export const decline_investor = (res) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.DECLINE_INVESTOR_REQUEST });

    const {
      auth: { detail },
    } = getState();

    const getData = window.localStorage.getItem("devoper");

    const { data } = await axios.post(
      `${url}/investor/decline-investor?application=${getData}`,
      res,
      {
        headers: authHeader(detail.token),
      }
    );

    if (data) {
      dispatch({ type: types.DECLINE_INVESTOR_SUCCESS, payload: data });
      dispatch(get_all_individual_investors());
      toast.success(`Investor has been declined for trades.`, {
        position: "top-right",
      });
    }
  } catch (error) {
    console.log(error);
    const message = error.response.data.error
      ? error.response.data.error
      : "Something went wrong";
    if (message === "Something went wrong") {
      dispatch(broker_logout());
    }
    dispatch({ type: types.DECLINE_INVESTOR_FAIL, payload: message });
    toast.error(message, { position: "top-right " });
  }
};

export const get_all_declined_investors = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_ALL_DECLINED_INVESTOR_REQUEST });

    const {
      auth: { detail },
    } = getState();

    const getData = window.localStorage.getItem("devoper");

    const { data } = await axios.get(
      `${url}/investor/decline-investor?application=${getData}`,
      {
        headers: authHeader(detail.token),
      }
    );

    if (data) {
      dispatch({
        type: types.GET_ALL_DECLINED_INVESTOR_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    const message = error.response.data.error
      ? error.response.data.error
      : "Something went wrong";
    if (message === "Something went wrong") {
      dispatch(broker_logout());
    }
    dispatch({ type: types.GET_ALL_DECLINED_INVESTOR_FAIL, payload: message });
    toast.error(message, { position: "top-right " });
  }
};

export const get_single_declined_investors =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_SINGLE_DECLINED_INVESTOR_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(
        `${url}/investor/decline-investor/${id}?application=${getData}`,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({
          type: types.GET_SINGLE_DECLINED_INVESTOR_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      if (message === "Something went wrong") {
        dispatch(broker_logout());
      }
      dispatch({
        type: types.GET_SINGLE_DECLINED_INVESTOR_FAIL,
        payload: message,
      });
      toast.error(message, { position: "top-right " });
    }
  };

export const get_investor_trade_orders = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_ALL_TRADE_ORDERS_REQUEST });

    const {
      auth: { detail },
    } = getState();

    const getData = window.localStorage.getItem("devoper");

    const { data } = await axios.get(
      `${url}/order/broker-orders/${id}?application=${getData}`,
      {
        headers: authHeader(detail.token),
      }
    );

    if (data) {
      dispatch({ type: types.GET_ALL_TRADE_ORDERS_SUCCESS, payload: data });
    }
  } catch (error) {
    console.log(error);
    const message = error.response.data.error
      ? error.response.data.error
      : "Something went wrong";
    if (message === "Something went wrong") {
      dispatch(broker_logout());
    }
    dispatch({ type: types.GET_ALL_TRADE_ORDERS_FAIL, payload: message });
    toast.error(message, { position: "top-right " });
  }
};

export const get_investor_single_trade_order =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_SINGLE_TRADE_ORDER_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.get(
        `${url}/order/single-order/${id}?application=${getData}`,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({ type: types.GET_SINGLE_TRADE_ORDER_SUCCESS, payload: data });
      }
    } catch (error) {
      console.log(error);
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      // if (message === "Something went wrong") {
      //   dispatch(broker_logout());
      // }
      // dispatch({ type: types.GET_SINGLE_TRADE_ORDER_FAIL, payload: message });
      toast.error(message, { position: "top-right " });
    }
  };

export const release_locked_funds =
  (apiData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_ALL_BANKS_REQUEST });

      const {
        auth: { detail },
      } = getState();

      const getData = window.localStorage.getItem("devoper");

      const { data } = await axios.post(
        `${url}/wallet/release-fund/order/${id}?application=${getData}`,
        apiData,
        {
          headers: authHeader(detail.token),
        }
      );

      if (data) {
        dispatch({ type: types.GET_ALL_BANKS_SUCCESS, payload: data });
        toast.success(`Fund has been released successfully!.`, {
          position: "top-right",
        });
      }
    } catch (error) {
      const message = error.response.data.error
        ? error.response.data.error
        : "Something went wrong";
      // if (message === "Something went wrong") {
      // 	dispatch(broker_logout());
      // }
      // dispatch({ type: types.GET_ALL_BANKS_FAIL, payload: message });
      toast.error(message, { position: "top-right " });
    }
  };
