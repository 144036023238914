import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card } from "@themesberg/react-bootstrap";

import {
  get_investor_single_trade_order,
  release_locked_funds,
} from "../../redux/actions/investorsActions";
import { get_all_banks } from "../../redux/actions/settlementAction";
import { get_all_brokers_account } from "../../redux/actions/authActions";
import ModalComponent from "../../components/ModalComponent";
import Meta from "../../components/Meta";

import "./styles.css";

const AdminSingleTradeRequest = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedBank, setSelectedBank] = React.useState("");

  const orders = useSelector((state) => state.singleOrder);
  const { brokerSingleOrder } = orders;
  const allLocalBanks = useSelector((state) => state.allBanks);
  const { getBanks } = allLocalBanks;
  const broker = useSelector((state) => state.broker);
  const { brokers } = broker;

  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const cancleModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
  };

  const getbroker =
    brokers &&
    brokers.length > 0 &&
    brokers.filter(
      (broker) => broker.brokerCode === brokerSingleOrder?.order?.brokerCode
    );

  const releaseFunds = (e) => {
    e.preventDefault();
    setOpenModal(false);

    const data = {
      brokerCode: brokerSingleOrder?.order?.brokerCode,
      bankCode: selectedBank,
      userId: brokerSingleOrder?.order?.userId,
    };
    dispatch(release_locked_funds(data, id));
  };

  React.useEffect(() => {
    Promise.all([
      dispatch(get_investor_single_trade_order(id)),
      dispatch(get_all_banks()),
      dispatch(get_all_brokers_account()),
    ]);
  }, [dispatch]);

  return (
    <>
      <Meta title="All Trade Requests For A Broker" />
      <section className="section">
        <div className="container-fluid">
          <div className="title-wrapper pt-30">
            <div className="release_funds">
              <div className="title mb-30">
                <h2>Trade Information</h2>
              </div>
              <div className="title mb-30">
                <button
                  type="button"
                  className="release__btn"
                  onClick={toggleModal}
                >
                  Release Funds
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "30px",
            }}
          >
            <Row>
              <Col lg={12}>
                <Card border="light" className="p-0 mb-4">
                  <Card.Body className="pb-5">
                    <Row>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Account Number</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.accountNo}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>User Name</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.clientName}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Broker Code</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.brokerCode}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Security</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.security}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Broker Account Number</label>
                          <input
                            type="text"
                            value={getbroker[0]?.accountNumber}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Broker Bank Name</label>
                          <input
                            type="text"
                            value={getbroker[0]?.bankName}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Quantity</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.units}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Trade Price</label>
                          <input
                            type="email"
                            value={brokerSingleOrder?.order.basePrice}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Value</label>
                          <input
                            type="text"
                            value={
                              brokerSingleOrder?.order.units *
                              brokerSingleOrder?.order.basePrice
                            }
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Total Trade Cost</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.finalTotal}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Broker Payout</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.totalTradeCost}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="input-style-1">
                          <label>Trade Status</label>
                          <input
                            type="text"
                            value={brokerSingleOrder?.order.status}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <ModalComponent
        open={openModal}
        title={`Release this Trade Fund to Broker.`}
      >
        <div className="my-3">
          <p>
            By selecting a Brokers bank and clicking on the release button
            below, you agree to transfer the cost of the trade below to the
            respective broker.
          </p>
        </div>
        <div className="my-3">
          <label>Select Brokers Bank</label>
          <select
            value={selectedBank}
            onChange={(e) => setSelectedBank(e.target.value)}
            className="select__bank-field"
          >
            {getBanks?.banks?.map((item) => (
              <option key={item.name} value={item.code}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5">
          <button
            className="main-btn danger-btn btn-hover m-1"
            onClick={cancleModal}
          >
            Cancel
          </button>
          <button
            className="main-btn primary-btn btn-hover m-1"
            style={{ backgroundColor: "#252063", color: "#ffffff" }}
            onClick={releaseFunds}
          >
            Release
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default AdminSingleTradeRequest;
