import React from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import { upload_eod_file } from "../redux/actions/tradesAction";
import "../pages/Dashboard/styles.css";

const FileUpload = () => {
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();

  const onDrop = React.useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setError(null);
    } else if (rejectedFiles.length > 0) {
      setError("Please upload a valid CSV file.");
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  const onDelete = () => setFile(null);

  const submitFile = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("file", file);

      const res = await dispatch(upload_eod_file(formData));

      if (res.status === 200) {
        toast.success(`EOD file was uploaded successfully!`, {
          position: "top-right",
        });
        onDelete();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message, { position: "top-right " });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="file-upload-container">
      <label className="file-upload-label">
        This is essential for running checks on duplicated trades.
      </label>
      <div {...getRootProps({ className: "file-upload-dropzone" })}>
        <input {...getInputProps()} />
        <div className="file-upload-content">
          <div className="upload-icon">↑</div>
          <p>
            Drag & drop or{" "}
            <span className="file-upload-choose">Choose file</span> to upload
          </p>
          <p className="file-upload-format">CSV</p>
        </div>
      </div>
      {file && (
        <div className="file-upload-selected">
          <span>Selected file: {file.name}</span>
          <RiDeleteBin5Line onClick={onDelete} className="delete-button" />
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      <div className="file-upload-button">
        <button className="upload-button" onClick={submitFile}>
          Upload EOD {loading && <i className="fas fa-spinner fa-spin"></i>}
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
