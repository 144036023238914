import React from "react";
import { Row, Col, Card } from "@themesberg/react-bootstrap";

import Meta from "../../components/Meta";
import FileUpload from "../../components/FileUpload";

const LoadEod = () => {
  return (
    <section className="section">
      <Meta title="Create a new broker account." />
      <div className="container-fluid">
        <div className="title-wrapper pt-30">
          <div className="row">
            <div className="col-md-6">
              <div className="title mb-30">
                <h2>Upload EOD File.</h2>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>

        <Row>
          <Col lg={12}>
            <Card border="light" className="p-0 mb-4">
              <Card.Body className="pb-5">
                <FileUpload />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default LoadEod;
